import React, { Component, Suspense } from "react";
import * as action from "../../Store/Actions/index";
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import "./TaskComponent.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import iconQuickPhone from "../../Assets/images/icon-quick-phone.svg";
import iconQuickEmail from "../../Assets/images/icon-quick-email.svg";
import taskDetailCompony from "../../Assets/images/task_detail_company.svg";
import taskDetailDivision from "../../Assets/images/task_detail_division.svg";
import emergency from "../../Assets/images/Group 757.svg";
import iconClose from "../../Assets/images/close_btn.svg";
import Switch from "@material-ui/core/Switch";
import momentTimeZone from 'moment-timezone';
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import PatientInfoComponent from "./PatientInfoComponent";
import {
  Typography,
  Button,
  Select,
  TextField,
  Tooltip,
  Dialog, DialogTitle, DialogContent, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, MenuItem
} from "@material-ui/core";
import {
  Breadcrumbs,
  Link,
  Grid,
} from "@material-ui/core";
import backIcon from "../../Assets/images/breadcrumb_back.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { AxiosInstance } from "../../Utilities";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import { Transition } from "../../SharedComponents/CommonUtilities";
import { isSafari } from "react-device-detect";
import moment from "moment";
var DurationComponent = React.lazy(() => import("./DurationComponent"));
var DescriptionComponent = React.lazy(() => import("./DescriptionComponent"));
var StepperComponent = React.lazy(() => import("./StepperComponent"));

const { RangePicker } = DatePicker;
// const moment = require("moment");
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'black',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.grey,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "15px",
    '& .MuiInput-underline:after': {
      borderBottomColor: '#14193A',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
        borderWidth: 0
      },
      '&.Mui-focused fieldset': {
        borderColor: '',
        borderWidth: 0
      },
    },
  },
})(TextField, Select);
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
  
class TaskDetailsComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    let data = window.location.href;
    const [baseUrl, taskId] = data.split("/task/");
    var cms  = [{"Patient Name":"Patient Name","value":"patientName","checked":false},{"Case Number":"Case Number","value":"caseNumber","checked":false},{"Patient Survey":"Patient Survey","value":"patientSurvey","checked":false}];
    this.state = {
      cms : cms,
      initialcms : cms,
      taskId: taskId,
      tasks: [],
      assigned: "",
      division: "",
      taskName: '',
      department: [],
      taskStatus: '',
      onCancelTaskDialogOpen: false,
      timeZoneName: localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
      isStartDate: true,
      isEndDate: true,
      isDesc: true,
      taskDescription: '',
      taskDescriptionError: "",
      priority: "",
      loading: true,
      durations: {
        startDate: momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
        endDate: momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
        time: ""
      },
      errduration: "",
      durationsNew: {
        startDateNew: momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
        endDateNew: momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
        time: ""
      },
      isStartDateEdit: false,
      startDateDialog: false,


      dateRangeProps: {
        startDate: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName)
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: ""
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName)
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control"
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
      },
      tooltipprops:{},
      cmsPatientName : {},
      cmsCaseNumber: {},
      cmsPatientSurvey: {},
      patientName : "",
      caseNumber : "",
      patientSurvey: '',
      initialPatientName : "",
      initialCaseNumber : "",
      initialPatientSurvey: '',
      isPatientName: false,
      isCaseNumber: false,
      isPatientSurvey: false,
      updateStatusDialogMenu: false,
      companyId: ''
    };
    this.editInput = this.editInput.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.initialFetch(this.state.taskId)
    this.setCMSData(this.state.cms)
  }
  componentDidUpdate() {
    var { id } = this.props.match.params;
    if (id !== this.state.taskId) {
      this.initialFetch(id);
    }
  }
  initialFetch = (id) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/task/detail/" + id)
      .then((response) => {
        var resp = response && response.data;
        if (resp && (resp.status == "200" || resp.status === 200)) {
          let res = response.data.data[0];
          const { dateRangeProps } = this.state;
          dateRangeProps.startDate.initialViewDate = momentTimeZone(res.startDate)
            .tz(this.state.timeZoneName);
          dateRangeProps.startDate.inputProps.value = momentTimeZone(res.startDate)
            .tz(this.state.timeZoneName);
          dateRangeProps.endDate.initialViewDate = momentTimeZone(res.endDate)
            .tz(this.state.timeZoneName);
          dateRangeProps.endDate.inputProps.value = momentTimeZone(res.endDate)
            .tz(this.state.timeZoneName);

          this.setState({
            dateRangeProps,
            tasks: response.data.data,
            loading: false,
            taskStatus: res.taskStatus,
            taskName: res.taskName,
            priority: res.priority,
            taskDescription: res.discription,
            durations: {
              startDate: res.startDate,
              endDate: res.endDate,
              time: ""
            },
            durationsNew: {
              startDateNew: res.startDate,
              endDateNew: res.endDate,
              time: ""
            },
            initialPatientName: res.patientName ? res.patientName : "",
            initialCaseNumber: res.caseNumber ? res.caseNumber : "",
            initialPatientSurvey: res.patientSurvey ? res.patientSurvey : '',
            companyId : res.company[0]._id ? res.company[0]._id : ""
          })
          this.getCms(res.company[0]._id)
        } else if (resp && (resp.status === "401" || resp.status === 401)) {
          var propsErr = {
            message: resp.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "bottomRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }

  getCms = (companyId) =>{
    if(companyId){
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 4004
        : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
      .get(`/cms/${companyId}`)
      .then((resp) => {
          if (resp.status === 200) {
              let response = resp.data.data;
              const labels = response?.labelChange?.label  ||  this.state.initialcms;
              this.setState({
                  cms : labels
              })
              this.setCMSData(labels)
          }
      });
    }
}

setCMSData = (cms)=> {
  if (cms && Array.isArray(cms)) {
    let cmsList = cms;
    cmsList = cmsList.filter(item => item?.checked)
    const patientNameObject = cmsList.find(item => item.value === "patientName");
    const caseNumberObject = cmsList.find(item => item.value === "caseNumber");
    const patientSurveyObject = cmsList.find(item => item.value === "patientSurvey");
    this.setState({
      cmsPatientName : patientNameObject || {},
      cmsCaseNumber: caseNumberObject || {},
      cmsPatientSurvey: patientSurveyObject || {},
      isPatientName: patientNameObject?.checked || false,
      isCaseNumber: caseNumberObject?.checked || false,
      isPatientSurvey: patientSurveyObject?.checked || false
    })
  }
 }

  onCancelTaskDialog = () => {
    this.setState({
      onCancelTaskDialogOpen: true
    })
  }
  onupdatestatusdialog = () => {
    this.setState({
      updateStatusDialogMenu: true,
      selectedStatus: "",
    });
  };
  /**
* @author Akshatha
* ON CANCEL DIALOG EXIT
*/
  onCancelTaskExit = () => {
    this.setState({
      // taskStatus:'cancelled',
      selectedStatus: "",
      addNote: ""
    })
  }
  /**
 * @author Akshatha
 * TO CLOSE TASK CANCEL DIALOG
 */
  onCancelTaskDialogClose = () => {
    this.setState({
      onCancelTaskDialogOpen: false
    })
  }
  /**
   * @author Akshatha
   * THE CANCEL TASK HANDLER
   */
  onCancelTask = () => {
    this.setState({
      selectedStatus: "cancelled",
    }, async () => {
      await this.updateStatusHandle();
      await this.onCancelTaskDialogClose();
    })
  }

  updateStatus = async() => {
      await this.updateStatusHandle();
      await this.handleUpdateDialogClose();
  }

  /**
   * @author Akshatha
   * TO UPDATE STATUS FROM DIALOG
   */
  updateStatusHandle = () => {
    var { taskId, selectedStatus, addNote } = this.state;
    var data = {
      timeZone:this.state.timeZoneName
    };
    if (addNote !== "") {
      data.note = addNote;
    }
    if (selectedStatus !== "" && taskId !== "") {
      data.taskStatus = selectedStatus;
      data._id = taskId;
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 4004
        : "clinical-task", "application/json")
        .put("/assign_task", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              taskStatus: selectedStatus,
              updateStatusDialogMenu: false,
              selectedStatus: "",
              addNote: ""
            }, () => {
              this.componentDidMount();
            })
            var props = {
              message: "Status Updated Successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "bottomRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
          } else {
            var propsErr = {
              message: "Update Status Failed",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "bottomRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else {
            console.log("Internal Server Error")
          }

        });
    } else {
      this.setState({
        updateStatusError: "Please select task status",
      });
    }
  }
  /**
  * @author Akshatha
  * @param {selected date} value 
  * @param {start and end date} name
  * TO SET START AND END DATE WHILE EDITING DURATIONS 
  */
  handleDateSelect = (value, name) => {
    var { durations, durationsNew } = this.state;
    if (name === "endDateNew" || name === "endDate") {
      this.setState({
        isStartDateEdit: true
      });
    }
    durations = { ...durations, [name]: momentTimeZone(new Date(value)).tz(this.state.timeZoneName) };
    durationsNew = { ...durationsNew, [name]: momentTimeZone(new Date(value)).tz(this.state.timeZoneName) };
    this.setState({
      durations,
      durationsNew
    })
  }
  changeStartDateDialogClose = () => {
    this.setState({
      startDateDialog: false,
      errduration: ""
    }, () => this.editInput("isStartDate"))
    this.componentDidMount();
  }
  resetStartDateDialog = () => {
    this.setState({
      isStartDateEdit: false
    })
  }
  updateHandler = (field) => {
    var { taskId, durations, durationsNew, newDesc, taskDescription } = this.state;
    var data = {
      timeZone:this.state.timeZoneName
    };
    switch (field) {
      case 'isDisc':
        data.task_id = taskId;
        data.discription = taskDescription;
        break;
      case 'isDesc':
        data.task_id = taskId;
        data.taskDescription = newDesc;
        break;
      case 'isStartDate':

        data.startDate = new Date(this.state.dateRangeProps.startDate.initialViewDate).toUTCString();
        data.endDate = new Date(this.state.dateRangeProps.endDate.initialViewDate).toUTCString();
        data.task_id = taskId;
        break;
      case 'isEndDate':
        data.endDate = new Date(this.state.dateRangeProps.endDate.initialViewDate).toUTCString();
        data.task_id = taskId;
        break;
      case 'isPatientName':
        data.patientName = this.state.patientName;
        data.task_id = taskId;
        break;
      case 'isCaseNumber':
        data.caseNumber = this.state.caseNumber;
        data.task_id = taskId;
        break; 
      case 'isPatientSurvey':
        data.patientSurvey = this.state.patientSurvey;
        data.task_id = taskId;
        break;          
      default:
        break;
    }

    let { errduration, } = this.state

    if (this.state.dateRangeProps.startDate.inputProps.value === "" && this.state.dateRangeProps.endDate.inputProps.value === "") {
      errduration = "Please enter start date and end date";
    } else if (
      momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A") >=
      momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A")
    ) {
      errduration =
        "End date should not be less than or equal to start date";
    } else {
      errduration = "";
    }
    this.setState({ errduration })
    if (errduration === "") {

      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 4004
        : "clinical-task", "application/json")
        .put("/task/edit", data)
        .then(resp => {
          var response = resp && resp.data;
          if (response && (response.status === "200" || response.status === 200)) {
            this.setState({ isDesc: true })
            if (field === 'isStartDate' || field === 'isEndDate' || field === 'isDesc') {
            }
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "bottomRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
            this.editInput(field);
            this.componentDidMount();
          } else if (response && (response.status === "400" || response.status === 400)) {
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "bottomRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
          else {
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "bottomRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else {
            console.log("Internal Server Error")
          }

        })
    }
  }
  /**
    * @author Akshatha
    * @param {FIELD NAME} name
    * TO SHOW AND HIDE EDIT FIELDS 
    */
  editInput = (name) => {
    switch (name) {
      case "isDesc":
        this.setState({ isDesc: !this.state.isDesc, taskDescriptionError: '' });
        break;
      case "isStartDate":
        this.setState({ isStartDate: !this.state.isStartDate, startDateDialog: true, isStartDateEdit: false });
        break;
      case "isEndDate":
        this.setState({ isEndDate: !this.state.isEndDate });
        break;
      case "isPatientName":
        this.setState({ isPatientName: !this.state.isPatientName });
        break;
      case "isCaseNumber":
        this.setState({ isCaseNumber: !this.state.isCaseNumber });
        break; 
      case "isPatientSurvey":
        this.setState({ isPatientSurvey: !this.state.isPatientSurvey });
        break;       
      default:
        break;
    }
  };
  /**
  * @author Akshatha
  * @param {TARGET ELEMENT} e 
  * SET STATE ON CHANGE EVENT
  */
  onChangeHandler = (e) => {
    e.preventDefault();
    var { taskDescriptionError } = this.state;
    var { name, value } = e.target;
    this.setState({
      [name]: value
    })
    switch (name) {
      case 'taskDescription':
        value.trim().length > 256 ?
          taskDescriptionError = "Description length should be less than 256 character" :
          taskDescriptionError = "";
        this.setState({ taskDescriptionError }, () => {
          if (this.state.taskDescriptionError === "") {
            this.setState({ newDesc: value })
          }
        });
        break;
      default:
        break;
    }
  }

  handleUpdateDialogClose = () => {
    this.setState({
      updateStatusDialogMenu: !this.state.updateStatusDialogMenu,
    });
  };

  onStartDateChange = (date, dateString) => {
    const { dateRangeProps } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(date._d);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(date._d);
    dateRangeProps.endDate.inputProps.disabled = false;
    dateRangeProps.endDate.inputProps.value = "";
    this.setState({
      dateRangeProps
    });
  }
  valid = (current) => {
    return current && current < moment().subtract(1, 'day');
  };
  onEndDateChange = (date, dateString) => {
    this.setState({
      isStartDateEdit: true
    });
    const { dateRangeProps, errduration } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date._d);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date._d);
    // errduration = ""
    this.setState({
      dateRangeProps,
      // errduration
    });
  }
  disabledDates = (current) => {
    return current && current.isBefore(moment(new Date(this.state.dateRangeProps.startDate.initialViewDate)));//.subtract(1, "day"));
  };
  handlechecked = (event) => {
    this.setState({
      priority: event.target.checked
    },

      () => {
        var data = {
          task_id: this.state.taskId,
          isEmergency: this.state.priority,
          timeZone:this.state.timeZoneName
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4004 : 'clinical-task', "application/json")
          .put("/task/edit", data)
          .then(resp => {
            var response = resp && resp.data;
            if (response && (response.status === "200" || response.status === 200)) {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "bottomRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.componentDidMount();
            } else if (response && (response.status === "400" || response.status === 400)) {
              var props1 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "bottomRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props1);
            }
            else {
              var props2 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props2);
            }
          }).catch(err => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else {
              console.log("Internal Server Error")
            }
          }
          )
      }
    );
  };
  render() {
    var finalStatus = [
      { id: "1", value: "cancelled", name: "Cancelled" },
      { id: "2", value: "completed", name: "Completed" },
    ];
    let DetailsPage = (
      <div className="body-bg-color add_company_dialog task_detail_dialog">
        <HeaderComponent />
        <div className="select_parent">
          <div className="breadCrumbs">
            <img className="bread-crumb-back" src={backIcon} alt="back" />
            <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
              <Link
                className="bread-crumb-link"
                color="inherit"
                href="/task"
              >
                Task
             </Link>
              <Typography className="bread-crumb-name">
                Task Details
               </Typography>
            </Breadcrumbs>
          </div>
          <div className="account-detail-header task-detail-header">
            <Typography variant="h5" className="tableTitle" component="div">
              {this.state.priority === true ?
                <>
                 {this.state.taskName ? this.state.taskName.charAt(0).toUpperCase() + this.state.taskName.slice(1) : '-'} 
                  <LightTooltip
                    title="Emergency Task"
                    {...this.state.tooltipprops}
                    arrow
                  >
                    <img
                      className="priority_icon"
                      src={emergency}
                      alt="priority icon"
                    />
                  </LightTooltip>
                </>
                :
                this.state.taskName ? this.state.taskName.charAt(0).toUpperCase() + this.state.taskName.slice(1) : '-'
              }
            </Typography>
            <div>
            <Suspense fallback={<div></div>}>
              <Dialog
                open={this.state.updateStatusDialogMenu}
                aria-labelledby="form-dialog-title"
                className="add_company_dialog add_account_dialog update_status_dialog"
                TransitionComponent={Transition}
                transitionDuration={600}
              >
                <DialogTitle id="form-dialog-title" className="add_user_header">
                  <span>Update Status</span>
                  <IconButton
                    aria-label="close"
                    className="closeBtn updateStatusCloseBtn"
                    onClick={this.handleUpdateDialogClose}
                  >
                    <img src={iconClose} alt="close"></img>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <label className="label select_status_label bl-label">
                    Select status
                  </label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={this.state.selectedStatus}
                      displayEmpty={true}
                      className="customSelect"
                      name="selectedStatus"
                      fullWidth
                      onChange={this.onChangeHandler}
                    >
                    {(finalStatus.map((status) => (
                          <MenuItem
                            key={status.id}
                            value={status.value}
                          >
                            {status.name}
                          </MenuItem>
                        ))
                      )
                    }
                    </Select>
                  </FormControl>
                  <span className="error"></span>
                  <div className="add_company_action">
                    <Button
                      onClick={this.handleUpdateDialogClose}
                      color="primary"
                      className="cancel_btn white-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.selectedStatus !== "" ? false : true}
                      className="ok_btn green-btn"
                      onClick={this.updateStatus}
                      color="primary"
                    >
                      Update
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </Suspense>
            {this.state.taskStatus.toLowerCase() === "cancelled" || this.state.taskStatus.toLowerCase() === "completed" || this.state.taskStatus.toLowerCase() === "uncovered" ?
              "" : 
                <Button
                  variant="contained"
                  className="tableaddBtn"
                  color="default"
                  style={{ boxShadow: 'none' }}
                  onClick={()=>this.onupdatestatusdialog()}
                >
                  Update Status
                </Button>}
              </div>
          </div>
          <div>
            <Suspense fallback={<div></div>}>
              <Dialog
                onExit={this.onCancelTaskExit}
                open={this.state.onCancelTaskDialogOpen}
                aria-labelledby="form-dialog-title"
                className="add_company_dialog add_account_dialog update_status_dialog"
                TransitionComponent={Transition}
                transitionDuration={600}
              >
                <DialogTitle
                  id="form-dialog-title"
                  className="add_user_header"
                >
                  <span>Cancel Task</span>
                  <IconButton
                    aria-label="close"
                    className="closeBtn updateStatusCloseBtn"
                    onClick={this.onCancelTaskDialogClose}
                  >
                    <img src={iconClose} alt="close"></img>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <label className="label">Add note</label>
                  <CssTextField
                    id="outlined-basic"
                    className="grey-input-field"
                    name="addNote"
                    variant="outlined"
                    type="text"
                    fullWidth
                    placeholder="Enter text"
                    onChange={this.onChangeHandler}
                  />
                  <div className="add_company_action">
                    <Button
                      className="ok_btn"
                      onClick={() => this.onCancelTask()}
                      color="primary"
                    >
                      Cancel Task
              </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </Suspense>
            <Suspense fallback={<div></div>}>
              <StepperComponent
                taskStatus={this.state.taskStatus}
              />
            </Suspense>
          </div>
          <div className="task_detail_container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                {this.state.tasks[0] !== undefined ? this.state.tasks[0].company.map((compname) => (
                  <div className="task_detail_container_item">
                    <h1>Information</h1>
                    <div className="task_detail_item_container">
                      <p>Company</p>
                      <div className="task_detail_item_value"><img src={taskDetailCompony} alt="" />
                        {compname.name}
                      </div>
                      {this.state.tasks[0].division !== undefined && this.state.tasks[0].company.size !== 'large' ? this.state.tasks[0].division.map((divname) => (
                        <>
                          <p>Division</p>
                          <div className="task_detail_item_value"><img src={taskDetailDivision} alt="" />
                            {divname.name}
                          </div>
                        </>
                      )) : ""}
                    </div>
                  </div>
                )) : ""}
                {this.state.tasks[0] !== undefined ? this.state.tasks[0].assigned.map((data) => (
                  <div className="task_detail_container_item">
                    <h1>Assignee :<span>{data.name}</span></h1>
                    <div className="task_detail_item_container">
                      <p>Phone</p>
                      <div className="task_detail_item_value contact_detail"><img src={iconQuickPhone} alt="" /><span> <a href={"tel:" + data.phone}>{data.phone ? data.phone :"--"}</a> </span></div>
                      <p>Email</p>
                      <div className="task_detail_item_value contact_detail"><img src={iconQuickEmail} alt="" /><span> <a href={"mailto:" + data.email }>{data.email ? data.email : "--"}</a> </span></div>
                    </div>
                  </div>
                )) : ""}
                <div className="priority_container">
                    <FormControlLabel
                    disabled={ this.state.taskStatus === "completed" || this.state.taskStatus==="cancelled" ? true :false}
                      checked={this.state.priority}
                      name="priority"
                      control={<AntSwitch color="primary" />}
                       onChange={this.handlechecked}
                      label="Priority"
                      margin="dense"
                      labelPlacement="start"
                    />
                    <label className="label labelEmergency">
                      <span className="labelAsterisk ">Emergency</span>
                    </label>
                  </div>
                <div className="task_detail_container_item">
                  <Suspense fallback={<div></div>}>
                    <DurationComponent
                      timeZoneName={this.state.timeZoneName}
                      taskStatus={this.state.taskStatus}
                      isStartDate={this.state.isStartDate}
                      isEndDate={this.state.isEndDate}
                      durations={this.state.durations}
                      durationsNew={this.state.durationsNew}
                      handleDateSelect={this.handleDateSelect}
                      resetStartDateDialog={this.resetStartDateDialog}
                      isStartDateEdit={this.state.isStartDateEdit}
                      updateHandler={this.updateHandler}
                      editInput={this.editInput}
                      startDateDialog={this.state.startDateDialog}
                      modaldata={this.state}
                      changeStartDateDialogClose={this.changeStartDateDialogClose}
                      onStartDateChange={this.onStartDateChange}
                      valid={this.valid}
                      onEndDateChange={this.onEndDateChange}
                      disabledDates={this.disabledDates}
                    />
                  </Suspense>
                </div>
                <div className="task_detail_patient_item">
                  <Suspense fallback={<div></div>}>
                  {((Object.keys(this.state.cmsPatientName).length > 0) || (Object.keys(this.state.cmsCaseNumber).length > 0) || (Object.keys(this.state.cmsPatientSurvey).length > 0)) ? (
                        <PatientInfoComponent
                          isPatientName={this.state.isPatientName}
                          isCaseNumber={this.state.isCaseNumber}
                          isPatientSurvey={this.state.isPatientSurvey}
                          initialPatientName={this.state.initialPatientName}
                          initialCaseNumber={this.state.initialCaseNumber}
                          initialPatientSurvey={this.state.initialPatientSurvey}
                          patientName={this.state.patientName}
                          caseNumber={this.state.caseNumber}
                          patientSurvey={this.state.patientSurvey}
                          editInput={this.editInput}
                          updateHandler={this.updateHandler}
                          onChangeHandler={this.onChangeHandler}
                          cmsPatientName={this.state.cmsPatientName}
                          cmsCaseNumber={this.state.cmsCaseNumber}
                          cmsPatientSurvey={this.state.cmsPatientSurvey}
                        />
                      ) : (
                        ""
                      )}
                  </Suspense>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div>
                  <h1 className="task_detail_table_header">Products</h1>
                  <div className="task_detail_container_itemtask_detail_table_container ">
                    <div className="Acc-Table company-detail-table">
                      <TableContainer >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>NO</StyledTableCell>
                              <StyledTableCell align="left">ITEM</StyledTableCell>
                              <StyledTableCell align="left">PRICE</StyledTableCell>
                              <StyledTableCell align="left">QUANTITY</StyledTableCell>
                              <StyledTableCell align="left">AMOUNT</StyledTableCell>
                              <StyledTableCell align="left">TOTAL</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.loading ? <StyledTableRow >
                              <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                <Loader
                                  type="ThreeDots"
                                  color="#00BFFF"
                                  height={50}
                                  width={50}
                                  visible={this.state.loading}
                                />
                              </TableCell>
                            </StyledTableRow> :
                              this.state.tasks[0] === undefined || this.state.tasks[0].length === 0 ? (
                                <StyledTableRow>
                                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                    No data found
                        </TableCell>
                                </StyledTableRow>
                              ) : (
                                  this.state.tasks[0].products === undefined ||
                                    this.state.tasks[0].products.length === 0 ? (
                                      <StyledTableRow>
                                        <TableCell
                                          colSpan={9}
                                          style={{ textAlign: "center" }}
                                        >
                                          No data found
                              </TableCell>
                                      </StyledTableRow>
                                    ) :
                                    this.state.tasks[0].products.map((prod, index) => (
                                      <StyledTableRow key={prod.no}>
                                        <StyledTableCell scope="row">
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{prod.name}</StyledTableCell>
                                        <StyledTableCell align="left">$ {prod.price}</StyledTableCell>
                                        <StyledTableCell align="left">{prod.quantity}</StyledTableCell>
                                        <StyledTableCell align="left">{(prod.price * prod.quantity).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell align="left">{(prod.price * prod.quantity).toFixed(2)}</StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
                <div className="task_detail_container_item">
                  <h1>Description</h1>
                  <Suspense fallback={<div></div>}>
                    <DescriptionComponent
                      taskStatus={this.state.taskStatus}
                      isDesc={this.state.isDesc}
                      taskDescription={this.state.tasks[0] !== undefined ? this.state.tasks[0].taskDescription : ""}
                      taskDescriptionError={this.state.taskDescriptionError}
                      onChangeHandler={this.onChangeHandler}
                      updateHandler={this.updateHandler}
                      editInput={this.editInput}
                    />
                  </Suspense>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div >
    )
    return <div>{DetailsPage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    // isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.pagination.pager,
    companyList: state.pagination.pageOfItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole,) => dispatch(action.companyPagination(pageNo, pagerole)),
    onSearchBy: (value) => dispatch(action.companySearch(value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaskDetailsComponent));
