import React, { Component, Suspense } from "react";
import * as action from "../../Store/Actions/index";
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import "./CompanyComponent.css";
import { Typography,Tooltip } from "@material-ui/core";
import { Breadcrumbs, Link, Grid } from "@material-ui/core";
import backIcon from "../../Assets/images/breadcrumb_back.svg";
import ComponyIcon from "../../Assets/images/company_icon.svg";
import iconQuickPhone from "../../Assets/images/icon-quick-phone.svg";
import iconQuickEmail from "../../Assets/images/icon-quick-email.svg";
import clinicalLocation from "../../Assets/images/clinical_location_icon.svg";
import clinicalWebsite from "../../Assets/images/clinical_website_icon.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AxiosInstance } from "../../Utilities";
import { lowerCase } from "lodash";
import 'antd/dist/antd.css';
import { isSafari } from "react-device-detect";
import TabComponent from "../TabComponent/TabComponent";
import validator from "validator";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "black",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
class CompanyDetailsComponent extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        let data = window.location.href;
        const [baseUrl, companyId] = data.split("/company/");
        const CMSLabel = [
            { "Patient Name": "", isEdited: false, value:"patientName", checked: false },
            { "Case Number": "", isEdited: false, value:"caseNumber", checked: false },
            { "Patient Survey": "", isEdited: false, value:"patientSurvey", checked: false }    
        ]
        this.state = {
            company: [],
            companyId: companyId,
            createdBy: userData.username,
            Size: userData.Size,
            companyLogo: null,
            loading: true,
            tooltipprops:{},
            activeTab : "1",
            serviceType:"",
            serviceId:"",
            serviceTypeList :  [],
            services:  [],
            menuAnchorEl: "",
            editId: "",
            showEdit: false,
            serviceFormError: {
                serviceName: "",
            },
            showdelete: false, 
            showAdd: false,
            deleteId: "",
            responseMsg: "",
            alertType: "",
            showuserdelete: false,
            disable: false,
            hideId: "",
            serviceName: "",
            divisionId : "",
            type:"",
            isSubmit : false,
            CMSLabel: CMSLabel,
            initialCms: [
                { "Patient Name": "", isEdited: false, value:"patientName", checked: false },
                { "Case Number": "", isEdited: false, value:"caseNumber", checked: false },
                { "Patient Survey": "", isEdited: false, value:"patientSurvey", checked: false }
            ],
            cmsData: [
                { "Patient Name": "", isEdited: false, value:"patientName", checked: false },
                { "Case Number": "", isEdited: false, value:"caseNumber", checked: false },
                { "Patient Survey": "", isEdited: false, value:"patientSurvey", checked: false }
            ]
        };
    }
    UNSAFE_componentWillMount(){
        let obj = {}
        if(isSafari){
          obj.open = false;
          this.setState({tooltipprops:obj})
        }
      }
    componentDidMount = () => {
        this.getCms();
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4006
              : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
            .get("/company/" + this.state.companyId)
            .then((resp) => {
                if (resp.status === 200) {
                    let response = resp.data.data;
                    this.setState({
                        company: response,
                        companyLogo: response[0]?.companyLogo,
                        loading: false,
                        serviceTypeList: response[0]?.serviceType || [],
                        divisionId : response[0]?.divisionId || "",
                        services : response[0]?.services || [],
                        type: response[0]?.type || ""
                    })
                } else{
                    console.log("err")
                }
            });
    }

    onActiveTabChange = (key) => {
        this.setState({
          activeTab: key,
        });
    };

    updateMenu = (e ,serviceId, service) => {
        const {  serviceTypeList } = this.state;
        const serviceType = serviceTypeList.find((serviceType) => serviceType._id === service.serviceType)?._id;
        this.setState({
          showEdit: true,
          menuAnchorEl: e.currentTarget,
          serviceName: service.service,
          serviceType : serviceType || "",
          serviceId: serviceId,
          serviceFormError: {
            serviceName: "",
          },
        });
    };

    deleteService = (id) =>{
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                  ? 4006
                  : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                .delete(`/service/${id}/${this.state.companyId}`, )
                .then((resp) => {
                    let response = resp.data;
                    if (response.status === 200) {
                        let response = resp.data.data;
                        this.setState({
                            showdelete: false,
                            serviceTypeList : response.serviceType,
                            services : response.service
                        })
                        var props = {
                            message: "Service deleted successfully.",
                            icon: <img src={iconNotificationSuccess} alt="success" />,
                            type: "success",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification success-notify level-error",
                        };
                        NotificationComponent(props);
                    } else {
                        this.setState({
                            showdelete: false
                        })
                        var props = {
                            message: "Something went wrong",
                            icon: <img src={iconNotificationError} alt="err" />,
                            type: "error",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification error-notify",
                        };
                        NotificationComponent(props);
                    }
                    
                });
    }

    hideButton = (id) => {
        this.setState({
          hideId: id,
        });
      };

    handleClose = () => {
        this.setState({
          responseMsg: "",
          showdelete: false,
          showuserdelete: false,
          disable: false,
        });
      };

    addService =() =>{
        var serviceData = {
            createdBy: this.state.createdBy,
            service: this.state.serviceName,
            serviceType: this.state.serviceType,
            divisionId: this.state.divisionId,
            companyId: this.state.companyId,
          };
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                  ? 4006
                  : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                .post("/service" , serviceData)
                .then((resp) => {
                    let response = resp.data;
                    if (response.status === 200) {
                        let response = resp.data.data;
                        this.setState({
                            showAdd:  false,
                            showEdit: false,
                            serviceTypeList : response.serviceType,
                            services : response.service
                        })
                        var props = {
                            message: "Service added successfully",
                            icon: <img src={iconNotificationSuccess} alt="success" />,
                            type: "success",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification success-notify level-error",
                        };
                        NotificationComponent(props);
                    } else {
                        var props = {
                            message: "Something went wrong",
                            icon: <img src={iconNotificationError} alt="err" />,
                            type: "error",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification error-notify",
                        };
                        NotificationComponent(props);
                    }
                });
    }

    editService =() =>{
        var updateServiceData = {
            service: this.state.serviceName,
            serviceType: this.state.serviceType,
            companyId: this.state.companyId,
            id: this.state.serviceId
          };
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                  ? 4006
                  : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                .patch("/service" , updateServiceData)
                .then((resp) => {
                    let response = resp.data;
                    if (response.status === 200) {
                        this.setState({
                            showAdd:  false,
                            showEdit: false,
                            serviceTypeList : response.serviceType,
                            services : response.service
                        })
                        var props = {
                            message: "Service updated successfully.",
                            icon: <img src={iconNotificationSuccess} alt="success" />,
                            type: "success",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification success-notify level-error",
                        };
                        NotificationComponent(props);
                    } else {
                        var props = {
                            message: "Something went wrong",
                            icon: <img src={iconNotificationError} alt="err" />,
                            type: "error",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification error-notify",
                        };
                        NotificationComponent(props);
                    }
                });
    }

    onDeleteHandleClick = (id) => {
        this.setState({ showdelete: true, deleteId: id });
    };

    setMenu = (e) => {
        this.setState({
          showAdd: true,
          serviceName: "",
          serviceFormError: {
            serviceName: "",
          },
        });
    };
    
    cmsEditHandler = (e, key, id) => {
        const { value } = e.target;
        const {cmsData} = this.state;
        const temp = cmsData?.map((list, index) =>
            index === id ? { ...list, [key]: value, isEdited: true } : list
          );
        this.setState({
            cmsData :temp
        })
    }

    cmsHandleChecked = (e) => {
        const { cmsData } = this.state;
        const temp = [...cmsData]
        temp.forEach((list, index) => {
            if (list.value === e.target.name) {
              list.checked = !list.checked;
            }
          }
          );
          this.setState({
            cmsData :temp
        })
    }

    onCloseCms = () => {
        this.setState({
            cmsData : this.state.initialCms
        })
    }

    cmsHandler = () => {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 4004
        : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
      .post(`/cms/${this.state.companyId}`, {cmsData : this.state.cmsData})
      .then((resp) => {
          if (resp.status === 200) {
            let response = resp.data.data;
            const labels = response.labelChange.label;
            this.setState({
                cmsData : labels || this.state.CMSLabel,
                initialCms : labels
            })
            var props = {
                message: "CMS updated successfully.",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "bottomRight",
                duration: 4,
                top: 200,
                className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
          } else {
            var props = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="err" />,
                type: "error",
                placement: "bottomRight",
                duration: 4,
                top: 200,
                className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
          }
      });
    }
    getCms = () =>{
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                  ? 4004
                  : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                .get(`/cms/${this.state.companyId}`)
                .then((resp) => {
                    if (resp.status === 200) {
                        let response = resp.data.data;
                        const labels = response?.labelChange?.label;
                        this.setState({
                            cmsData : labels || this.state.CMSLabel,
                            initialCms : labels
                        })
                    }
                });
    }
      handleMenuClose = () => {
        this.setState({
            showAdd: false,
            showEdit: false,
            serviceType:"",
            serviceId:"",
        });
      };

      handleChange = (event) => {
        let value = event.target.value;
        const { serviceFormError } = this.state;
        if (!validator.isLength(value, { max: 100 })) {
          serviceFormError.serviceName =
            "* Service Name should not exceed 100 characters.";
        }
        else if (value.trim() === "") {
          serviceFormError.serviceName =
            "* Please enter a Service name.";
        }else {
          serviceFormError.serviceName = "";
        }
        this.setState({
          serviceName: value,
          serviceFormError,
        });
      };

      selectServiceType = (event) =>{
        let value = event.target.value;
        this.setState({
            serviceType : value
        })
      }
    
    render() {
        const {companyLogo} = this.state;
        let logoImg = "";
        if ( companyLogo === null ) {
          logoImg = "";
        } else {
          logoImg = 'https://salesc2companyprofile.s3.amazonaws.com/' + companyLogo;
        }
        let DetailsPage = (
            <div className="body-bg-color">
                <HeaderComponent />
                <div className="select_parent">
                    <div className="breadCrumbs">
                    <img className="bread-crumb-back" src={backIcon} alt="back" />
                        <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                            <Link
                                className="bread-crumb-link"
                                color="inherit"
                                href="/company"
                            >
                                Company
                            </Link>
                            <Typography className="bread-crumb-name">
                                Company Details
                            </Typography>
                        </Breadcrumbs>
                    </div>
                    <div className="company_detail_main">
                        <img className="johnson_logo" src={logoImg} alt="" />
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <div className="company_detail_left">
                                    <div className="company_detail_left_header" >
                                        <img className="" src={ComponyIcon} alt="" />
                                        <h1>{this.state.company[0] !== undefined ? this.state.company[0].companyName : ""}</h1>
                                    </div>
                                    <div className="company_detail_left_content">
                                        <div className="company_detail_left_item">
                                            <p>Phone :</p>
                                            <div className="company_detail_left_item_value">
                                                <img src={iconQuickPhone} alt="" /><span>{this.state.company[0] !== undefined ? <a href= {"tel:"}>{this.state.company[0].phone}</a> : ""}</span>
                                            </div>
                                        </div>
                                        <div className="company_detail_left_item">
                                            <p>Email :</p>
                                            <div className="company_detail_left_item_value">
                                                <img src={iconQuickEmail} alt="" /><span>{this.state.company[0] !== undefined ? <a href= {"mailto:"}>{this.state.company[0].email}</a> : ""}</span>
                                            </div>
                                        </div>
                                        <div className="company_detail_left_item">
                                            <p>Address :</p>
                                            <div className="company_detail_left_item_value">
                                                <img src={clinicalLocation} alt="" />
                                                <LightTooltip title={this.state.company[0] !== undefined ? this.state.company[0].address : ""} {...this.state.tooltipprops} arrow>
                                                                <span>{this.state.company[0] !== undefined ? this.state.company[0].address : ""}</span>
                                                            </LightTooltip>
                                    

                                            </div>
                                        </div>
                                        <div className="company_detail_left_item">
                                            <p>Website :</p>
                                            <div className="company_detail_left_item_value">
                                            <img src={clinicalWebsite} alt="" />
                                                <span>
                                                    {this.state.company[0] ?
                                                        <a
                                                            href={lowerCase(this.state.company[0].website && this.state.company[0].website.substring(0, 3)) === 'www' ? '//' + this.state.company[0].website : this.state.company[0].website}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >

                                                            {this.state.company[0].website !== undefined
                                                                ? this.state.company[0].website
                                                                    .replace(
                                                                        /^(?:https?:\/\/)?(?:www\.)?/i,
                                                                        ""
                                                                    )
                                                                    .split("/")[0]
                                                                : "N/A"}
                                                        </a>
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Suspense>
                                <TabComponent 
                                 onActiveTabChange={this.onActiveTabChange}
                                 updateMenu={this.updateMenu}
                                 onDeleteHandleClick={this.onDeleteHandleClick}
                                 handleClose={this.handleClose}
                                 deleteService={this.deleteService}
                                 hideButton={this.hideButton}
                                 handleMenuClose={this.handleMenuClose}
                                 setMenu={this.setMenu}
                                 addService={this.addService}
                                 editService={this.editService}
                                 handleChange={this.handleChange}
                                 selectServiceType={this.selectServiceType}
                                 cmsEditHandler={this.cmsEditHandler}
                                 cmsHandleChecked={this.cmsHandleChecked}
                                 onCloseCms={this.onCloseCms}
                                 cmsHandler={this.cmsHandler}
                                 data={this.state}
                                />
                                </Suspense>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div >
        )
        return <div>{DetailsPage}</div>;
    }
}
const mapStateToProps = (state) => {
    return {
        loginError: state.auth.error,
        pager: state.pagination.pager,
        companyList: state.pagination.pageOfItems,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPagination: (pageNo, pagerole,) => dispatch(action.companyPagination(pageNo, pagerole)),
        onSearchBy: (value) => dispatch(action.companySearch(value)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyDetailsComponent));
