import React, { Component } from "react";
import "date-fns";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker } from "antd";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import {
  withStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import addTaskIcon from "../../Assets/images/add_task_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import { Autocomplete } from "@material-ui/lab";
import { AxiosInstance } from "../../Utilities";
import axios from "axios";
import ReactTagInput from "@pathofdev/react-tag-input";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import "@pathofdev/react-tag-input/build/index.css";
import {
  emailRegx,
} from "../../SharedComponents/Validation/formValidation";
import momentTimeZone from 'moment-timezone';
import { Scrollbars } from 'react-custom-scrollbars';
import { Transition } from "../../SharedComponents/CommonUtilities";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#376CE2",
        border: `1px solid #376CE2`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "15px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField);

const CssAutocomplete = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "15px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(Autocomplete);

var i = 0;
export class AddTaskModal extends Component {
  constructor(props) {
    const inputDateTime = props.selectedDate

    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var zoneName = momentTimeZone.tz.guess();
    var cms  = [{"Patient Name":"Patient Name","value":"patientName","checked":false},{"Case Number":"Case Number","value":"caseNumber","checked":false},{"Patient Survey":"Patient Survey","value":"patientSurvey","checked":false}];
    this.state = {
      userRole: userData.role.toLowerCase(),
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      initialcms : cms,
      cms: cms,
      companyOption: [

      ],
      deptOption: [
        {
          id: "one",
          name: "No data",
        },
      ],
      serviceOption: [
        {
          id: "one",
          service: "No data",
        },
      ],
      divisionOption: [
        {
          id: "one",
          name: "No data",
        },
      ],
      formError: {
        companyName: "",
        departmentName: "",
        serviceName: "",
        divisionName: "",
        startDate: "",
        endDate: "",
        cc: '',
      },
      responseMsg: '',
      companyName: "",
      departmentName: "",
      serviceName: "",
      divisionName: "",
      companyId: "",
      departmentId: '',
      divisionId: "",
      serviceId: "",
      priority: "",
      taskStatus: "",
      cc: "",
      companySize: '',
      ccEmails: [],
      emails: [],
      isSubmit: false,
      divData: [{
        id: "",
        name: ""
      }],
      serviceData: [
        {
          id: "",
          service: ""
        }
      ],
      datas: userData,
      dateRangeProps: {
        startDate: {
          initialViewDate:  inputDateTime
          ? momentTimeZone(inputDateTime).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("MM-DD-YYYY hh:mm A") 
          : momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value:  inputDateTime
            ? momentTimeZone(inputDateTime).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            . format("MM-DD-YYYY hh:mm A") : ""
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate:  inputDateTime
          ? momentTimeZone(inputDateTime).add(1, 'hours').tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("MM-DD-YYYY hh:mm A") 
          : momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: inputDateTime ? false : true,
            value: inputDateTime
            ? momentTimeZone(inputDateTime).add(1, 'hours').tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            . format("MM-DD-YYYY hh:mm A") : "",
            className: "endDate form-control"
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        cmsPatientName : {},
        cmsCaseNumber: {},
        cmsPatientSurvey: {},
        patientName : "",
        caseNumber : "",
        patientSurvey: "",
      },
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      departmentId: this.state.userRole.toLowerCase() === 'scheduler' ? this.state.datas.departmentId : '',
      departmentName: this.state.userRole.toLowerCase() === 'scheduler' ? this.state.datas.departmentName : '',
    })
    var zoneName = momentTimeZone.tz.guess();
    this.setState({
      startDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      endDate: momentTimeZone().add(1, 'hours').tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),

    })
  }

  componentDidMount=()=>{
    this.setCMSData(this.state.cms)
  }

  setCMSData = (cms)=> {
    if (cms && Array.isArray(cms)) {
      const cmsList = cms;
      const patientNameObject = cmsList.find(item => item.value === "patientName");
      const caseNumberObject = cmsList.find(item => item.value === "caseNumber");
      const patientSurveyObject = cmsList.find(item => item.value === "patientSurvey");
      this.setState({
        cmsPatientName : patientNameObject || {},
        cmsCaseNumber: caseNumberObject || {},
        cmsPatientSurvey: patientSurveyObject || {}
      })
    }
  }
  /**
   * @author Murali
   * To get company list in autocomplete
   */
  companyListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ companyName: name });
    var data = {
      search: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companyFilter", data)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({
            //setData
            companyOption: res.data.data,
          });
        } else {
          this.setState({
            companyOption: [
              {
                id: "one",
                name: "No Option",
              },
            ],
          });
        }
      });
    var { companyOption, formError, companyName } = this.state;
    var data = await companyOption.filter((item) => {
      return item.name === name;
    });
    data.map((companyData) => {
      this.setState({
        companyName: companyData.name,
        companyId: companyData._id,
        companySize: companyData.size,
        [formError.companyName]: "",
      });
    });
  };
  /**
   * @author Murali
   * To get the selected company from dropdown
   */
  onSelectHandler = (e) => {
    this.setState({
      divData: [{ id: "", name: "" }],
      serviceData: [{ id: "", service: "" }],
      divisionOption: [{ id: "", name: "" }],
      serviceOption: [{ id: "", service: "" }]
    });
    var name = e.target.value;
    if (name === '') {
      this.companyListHandler(e);
      this.serviceListHandler(e)
      this.divisionListHandler(e);
      this.setState({ divisionName: "", })
    } else {
      var { companyOption, formError } = this.state;
      var data = companyOption.filter((item) => {
        return item.name === name;
      });
      formError.companyName = "";
      data.map((companyData) => {
        this.setState({
          serviceName: "",
          divisionName: "",
          companyId: companyData._id,
          companyName: companyData.name,
          companySize: companyData.size,
          formError
        });
        this.getCms(companyData._id)
      });
    }
  };

  getCms = (companyId) =>{
    console.log("get cms")
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
            .get(`/cms/${companyId}`)
            .then((resp) => {
                if (resp.status === 200) {
                    let response = resp.data.data;
                    const labels = response?.labelChange?.label  ||  this.state.initialcms;
                    this.setState({
                        cms : labels
                    })
                    this.setCMSData(labels)
                }
            });
}
  /**
   * @author Murali
   * To get division list based on selected company in autocomplete
   */
  divisionListHandler = async (e) => {
    var name = e.target.value;
    var companyId = this.state.companyId;
    var data = {
      search: name,
      companyId: companyId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/divisionFilter", data)
      .then((res) => {
        if (res.data.status == "200") {
          this.setState({
            //setData
            divisionOption: res.data.data,
          });
        } else {
          this.setState({
            divisionOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      });
    var { divisionOption, formError } = this.state;
    var data = await divisionOption.filter((item) => {
      return item.name === name;
    });
    data.map((repData) => {
      this.setState({
        divisionId: repData._id,
        divisionName: repData.name,
        [formError.divisionName]: "",
      });
    });
  };
  /**
   * @author Murali
   * To get selected division from the dropdown
   */
  onSelecdivisionHandler = (e) => {
    this.setState({
      serviceData: [{ id: "", service: "" }],
      divisionOption: [{ id: "", name: "" }],
      serviceOption: [{ id: "", service: "" }]
    })
    var name = e.target.value;
    if (name === '') {
      this.divisionListHandler(e);
      this.serviceListHandler(e)
    } else {
      var { divisionOption, formError } = this.state;
      var data = divisionOption.filter((item) => {
        return item.name === name;
      });
      formError.divisionName = "";
      data.map((repData) => {

        this.setState({
          serviceName: "",
          divisionId: repData._id,
          divisionName: repData.name,
          formError,
          divData: [{ id: repData._id, name: repData }]
        });
      })
    }
  };
  /**
     * @author Murali
     * To get service list based on selected company and division in autocomplete
     */
  serviceListHandler = async (e) => {
    var name = e.target.value;
    var companyId = this.state.companyId;
    var divisionId = this.state.divisionId;
    this.setState({ serviceName: name });
    var data = {
      search: name,
      companyId: companyId,
      divisionId: divisionId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/serviceFilter", data)
      .then((res) => {
        if (res.data.status == "200") {
          this.setState({
            serviceOption: res.data.data,
          });
        } else {
          this.setState({
            serviceOption: [
              {
                id: "one",
                service: "No data",
              },
            ],
          });
        }
      });
    var { serviceOption, formError } = this.state;
    var data = await serviceOption.filter((item) => {
      return item.service === name;
    });
    data.map((services) => {
      this.setState({
        serviceName: services.service,
        serviceId: services._id,
        [formError.serviceName]: "",
      });
    });
  };
  /**
     * @author Murali
     * To get selected service from the dropdown
     */
  onSelectServiceHandler = (e) => {
    var name = e.target.value;
    if (name === '') {
      this.serviceListHandler(e);
    } else {
      var { serviceOption, formError } = this.state;
      var data = serviceOption.filter((item) => {
        return item.service === name;
      });
      formError.serviceName = "";
      data.map((services) => {
        this.setState({
          serviceId: services._id,
          serviceName: services.service,
          formError,
          serviceData: [
            {
              id: services._id,
              service: services
            }]
        });
      })
    }
  };
  /**
     * @author Murali
     * To get department list based on selected company in autocomplete
     */
  departmentListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ departmentName: name });
    var companyId = this.state.companyId;
    var data = {
      search: name,
      companyId: companyId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/departmentSearch", data)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({
            //setData
            deptOption: res.data.data,
          });
        } else {
          this.setState({
            deptOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      });
    var { deptOption, formError } = this.state;
    var data = await deptOption.filter((item) => {
      return item.name === name;
    });
    data.map((deptData) => {
      this.setState({
        departmentId: deptData._id,
        departmentName: deptData.name,
        [formError.departmentName]: "",
      });
    });
  };
  /**
     * @author Murali
     * To get selected department from the dropdown
     */
  onSelectDeptHandler = (e) => {
    var name = e.target.value;
    if (name === '') {
      this.departmentListHandler(e);
    } else {
      var { deptOption, formError } = this.state;
      var data = deptOption.filter((item) => {
        return item.name === name;
      });
      formError.departmentName = "";
      data.map((deptData) => {
        this.setState({
          departmentId: deptData._id,
          departmentName: deptData.name,
          formError
        });

      })
    }
  };

  /**
     * @author Murali
     * To format the startdate and change the enddate according to it
     */
  handlestartDataSelect = (value) => {
    const target = value; // Do we need this?(unused in the function scope)!
    var date = new Date(target);
    var newformat = date.getHours() >= 12 ? "PM" : "AM";
    var hours = date.getHours() % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;

    var newendDate = (date.getHours() + 1) >= 12 ? "PM" : "AM";
    var hourendDate = (date.getHours() + 1) % 12;
    hourendDate = hourendDate ? hourendDate : 12;
    hourendDate = hourendDate < 10 ? "0" + hourendDate : hourendDate;

    var minutes = date.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var formatted =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      hours +
      ":" +
      minutes +
      " " +
      newformat;
    var formattedendDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      hourendDate +
      ":" +
      minutes +
      " " +
      newendDate;
    this.setState(
      {
        startDate: momentTimeZone(formatted).tz(this.state.timeZoneName),
        endDate: momentTimeZone(formattedendDate).tz(this.state.timeZoneName),
      },
      () => {

      }
    );
  };
  /**
     * @author Murali
     * To format the end date
     */
  handleendDataSelect = (value) => {
    const target = value; // Do we need this?(unused in the function scope)!
    var date = new Date(target);
    var newformat = date.getHours() >= 12 ? "PM" : "AM";
    var hours = date.getHours() % 12;
    var minutes = date.getMinutes();
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var formatted =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      hours +
      ":" +
      minutes +
      " " +
      newformat;
    var { formError } = this.state;
    if (momentTimeZone(this.state.startDate).tz(this.state.timeZoneName) >= momentTimeZone(formatted).tz(this.state.timeZoneName)) {
      formError.endDate = "End date shouldn't be less than or equal to start date";
    } else {
      formError.endDate = "";
    }
    this.setState(
      {
        endDate: momentTimeZone(formatted).tz(this.state.timeZoneName),
        formError
      },
      () => {

      }
    );
  };

  /**
   * @author Murali
   * To submit the form with necessary details
   */
  addTaskHandler = (e) => {
    this.setState({ isSubmit: true })
    e.preventDefault();
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "token"
    )
      ? localStorage.getItem("token")
      : "";
    var {
      companyName,
      departmentName,
      serviceName,
      divisionName,
      companyId,
      divisionId,
      departmentId,
      serviceId,
      priority,
      taskDescription,
      taskStatus,
      ccEmails,
      companySize,
      formError,
      userRole, startDate, endDate
    } = this.state;

    if (companyName == "") {
      formError.companyName = "* Please select a company";
    } else {
      formError.companyName = "";
    }
    if (departmentId == "" && userRole !== 'scheduler') {
      formError.departmentName = "* Please select a department";
    } else {
      formError.departmentName = "";
    }
    if (divisionName == "" && divisionId !== "") {
      companySize.toLowerCase() !== 'large' ? formError.divisionName = "" : formError.divisionName = "* Please select division again";
    } else {
      formError.divisionName = "";
    }

    if (serviceId == "") {
      formError.serviceName = "* Please select a service";
    } else {
      formError.serviceName = "";
    }

    if (serviceName == "" && serviceId !== "") {
      formError.serviceName = "* Please select a service again";
    } else {
      // formError.serviceName = "";
    }

    if (startDate == "") {
      formError.startDate = "* Please select start date";
    } else {
      formError.startDate = "";
    }
    if (endDate == "") {
      formError.endDate = "* Please select to date";
    } else {
      formError.endDate = "";
    }
    if (this.state.dateRangeProps.startDate.inputProps.value === "" && this.state.dateRangeProps.endDate.inputProps.value === "") {
      formError.endDate = "Please enter start date and end date";
    } else if (
      momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A") >=
      momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A")
    ) {
      formError.endDate =
        "End date should not be less than or equal to start date";
    } else {
      formError.endDate = "";
    }
    this.setState({ formError }, () => {
      var userData = {
        timezone: this.state.timeZoneName,
        companyName: this.state.companyName,
        departmentName: this.state.departmentName,
        serviceName: this.state.serviceName,
        companyId,
        departmentId,
        serviceId,
        divisionId: divisionId ? divisionId : null,
        startDate: new Date(
          this.state.dateRangeProps.startDate.initialViewDate
        ).toUTCString(),
        endDate: new Date(
          this.state.dateRangeProps.endDate.initialViewDate
        ).toUTCString(),
        priority,
        taskDescription,
        ccEmails,
        patientName : this.state.patientName || "",
        caseNumber : this.state.caseNumber || "",
        patientSurvey: this.state.patientSurvey || ''
        //AddData need to add rest of the field data
      };

      const isEmpty = Object.values(formError).every(
        (x) => x === null || x === ""
      );
      if (isEmpty) {
        this.setState({ isSubmit: true });
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 4004
          : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/addTask", userData)
          .then((res) => {
            var response = res.data;
            if (response.status == "403") {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isSubmit: false,
              });
            } else if (response.status == "200") {
              this.setState({
                responseMsg: response.msg,
                alertType: "success",
                isSubmit: false,
              });
              if(this?.props?.taskAdded) this.props.taskAdded();
          var props = {
              message: "Task added successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "bottomRight",
              duration: 4,
              top: 200,
              className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);
              setTimeout(() => {
                this.onClose();
              }, 1500);
            } else if (response.status == "409") {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isSubmit: false,
              });
            } else if (response.status == 400) {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isSubmit: false,
              });
            }
          })
          .catch(err => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else {
              console.log("Internal Server Error")
            }
          });
      } else {
        this.setState({
          isSubmit: false,
        });
      }
    });
  };
  /**
     * @author Murali
     * To get the toggle button value
     */
  handlechecked = (event) => {
    this.setState({
      [event.target.name]: !event.target.checked
        ? event.target.value
        : event.target.checked,
    });
  };
  onClose = () => {
    const { dateRangeProps } = this.state;
    dateRangeProps.startDate.inputProps.value = "";
    dateRangeProps.endDate.inputProps.value = "";
    dateRangeProps.endDate.inputProps.disabled = true;
    this.setState({
      dateRangeProps,
      companyName: "",
      departmentId: "",
      departmentName: "",
      serviceName: "",
      divisionName: "",
      companyId: "",
      divisionId: "",
      serviceId: "",
      responseMsg: "",
      alertType: "",
      formError: {
        companyName: "",
        departmentName: "",
        serviceName: "",
        divisionName: "",
        startDate: "",
        endDate: "",
        cc: '',
        ccEmails: "",
      }
    }, () => {
      this.props.onClose();
    })
  }
  clearDivision = (event, value, reason) => {
    console.log("reason--", reason)
    if (reason === 'reset') {
      this.setState({
        divData: [
          {
            id: "",
            name: "",
          },
        ],
        serviceData: [
          {
            id: "",
            service: "",
          },
        ],
        serviceName: "",
        divisionName: "",
        divisionId: "",
        serviceId: ""
      })
    } else if (reason === 'clear') {
      this.setState({
        divData: [
          {
            id: "",
            name: "",
          },
        ],
        serviceData: [
          {
            id: "",
            service: "",
          },
        ],
        serviceName: "",
        divisionName: "",
        divisionId: "",
        serviceId: ""
      })
    }
  }

  clearService = (event, value, reason) => {
    console.log("clear--", event, reason)
    if (reason === 'clear') {
      this.setState({
        serviceData: [
          {
            id: "",
            service: "",
          },
        ]
      })
    }
  }

  onStartDateChange = (date, dateString) => {
    const { dateRangeProps, formError } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(date._d);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(date._d);
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date._d).add(1, 'hours');
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date._d).add(1, 'hours');
    dateRangeProps.endDate.inputProps.disabled = false;
    formError.endDate = ""
    this.setState({
      dateRangeProps,
      formError
    });
  }
  valid = (current) => {
    return current && current < moment().subtract(1, 'day');
  };
  onEndDateChange = (date, dateString) => {
    const { dateRangeProps, formError } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date._d);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date._d);
    formError.endDate = ""
    this.setState({
      dateRangeProps,
      formError
    });
  }
  disabledDates = (current) => {
    return current && current.isBefore(moment(new Date(this.state.dateRangeProps.startDate.initialViewDate)));//.subtract(1, "day"));
  };

  render() {
    const { ccEmails } = this.state;
    const myStyle = {};
    var { formError, userRole, companyId, divisionId, companySize, cmsPatientName, cmsCaseNumber, cmsPatientSurvey} = this.state;
    return (
      <div>
        <Dialog
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog add_task_dialog add_task_modal"
          disableBackdropClick={true}
          TransitionComponent={Transition}
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img src={addTaskIcon} className="add-company_img"></img>
            <span>Add Task</span>
            <IconButton
              onClick={this.onClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn}></img>
            </IconButton>
          </DialogTitle>
          <Scrollbars className="custom_scrollbar" style={{ width: "auto", height: 541 }}>
            <DialogContent>
              <form>
                {this.state.responseMsg != "" ? (
                  <Alert severity={this.state.alertType}>
                    {this.state.responseMsg}
                  </Alert>
                ) : null}
                <Grid container spacing={2} className="register-form-top">
                  <Grid item xs={12} sm={6} className="register-form-left">
                    <div className="form_input_field">
                      <label className="label">
                        Company <span className="labelAsterisk"> *</span>
                      </label>
                      <CssAutocomplete
                        id="companyName"
                        className="cusautocomplete"
                        options={this.state.companyOption}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => true}
                        onInputChange={this.clearDivision}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="companyName"
                            placeholder="Select Company"
                            onChange={this.companyListHandler}
                            onSelect={this.onSelectHandler}
                          />
                        )}
                      />
                      <span className="error"> {formError.companyName}</span>
                    </div>
                    <div className="form_input_field">
                      {this.state.companySize === "large" ?
                        <label className="label">
                          Division <span className="labelAsterisk"> *</span>
                        </label>

                        :
                        <label className="label">Division</label>
                      }
                      <CssAutocomplete
                        className="cusautocomplete divisionId"
                        disabled={companyId === '' || (companySize.toLowerCase() === 'medium' || companySize.toLowerCase() === 'small') ? true : false}
                        options={this.state.divisionOption}
                        getOptionLabel={(option) => option.name}
                        onInputChange={this.clearDivision}
                        value={this.state.divData && this.state.divData.length > 0 ? this.state.divData[0].name : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="assignedTo"
                            placeholder={companyId === '' || (companySize.toLowerCase() === 'medium' || companySize.toLowerCase() === 'small') ? 'Disabled' : "Division"}
                            onChange={this.divisionListHandler}
                            onSelect={this.onSelecdivisionHandler}
                          />
                        )}
                      />
                      <span className="error"> {formError.divisionName}     </span>
                    </div>
                    <div className="form_input_field">
                      <label className="label">
                        Service<span className="labelAsterisk"> *</span>
                      </label>
                      <CssAutocomplete
                        id="service"
                        className="cusautocomplete"
                        disabled={companyId === '' && divisionId === '' ? true : false}
                        options={this.state.serviceOption}
                        getOptionLabel={(option) => option.service}
                        onInputChange={this.clearService}
                        value={this.state.serviceData && this.state.serviceData.length > 0 ? this.state.serviceData[0].service : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="serviceName"
                            placeholder="Service"
                            onChange={this.serviceListHandler}
                            onSelect={this.onSelectServiceHandler}
                          />
                        )}
                      />
                      <span className="error">{formError.serviceName}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="register-form-right">
                    <div className="switch-container">
                      <FormControlLabel
                        value="false"
                        name="priority"
                        control={<AntSwitch color="primary" />}
                        onChange={this.handlechecked}
                        label="Priority"

                        labelPlacement="start"
                      />
                      <div className="form_input_field">
                        <label className="label labelEmergency">
                          <span className="labelAsterisk ">Emergency</span>
                        </label>
                        <span className="error"></span>
                      </div>
                    </div>
                    <div className="form_input_field">
                      <label className="label">
                        Department<span className="labelAsterisk"> *</span>
                      </label>
                      {userRole === 'scheduler' ?
                        <CssAutocomplete
                          id="departmentName"
                          disabled={this.state.userRole === 'scheduler' ? true : false}
                          className="cusautocomplete"
                          options={this.state.deptOption}
                          getOptionLabel={(option) => option.name}
                          inputValue={this.state.departmentName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="departmentName"

                              placeholder={userRole === 'scheduler' ? 'Disabled' : "Select Department"}
                              onChange={this.departmentListHandler}
                              onSelect={this.onSelectDeptHandler}
                            />
                          )}
                        /> : <CssAutocomplete
                          id="departmentName"
                          disabled={userRole === 'scheduler' ? true : false}
                          className="cusautocomplete"
                          options={this.state.deptOption}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="departmentName"

                              placeholder={userRole === 'scheduler' ? 'Disabled' : "Select Department"}
                              onChange={this.departmentListHandler}
                              onSelect={this.onSelectDeptHandler}
                            />
                          )}
                        />}
                      <span className="error">
                        {formError.departmentName}
                      </span>
                    </div>
                    <div className="form_input_field form_multi_mail_field">
                      <label className="label">Email IDs of External Attendees:</label>
                      {/* <ReactTagInput
                        name='cc'
                        tags={this.state.ccEmails}
                        placeholder="Type Email and Press Enter"
                        maxTags={10}
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={false}
                        onChange={(newTags) => {
                          const { formError } = this.state;
                          formError.cc = '';
                          this.setState({ ccEmails: newTags, formError })
                        }}
                        validator={(value) => {
                          const { formError } = this.state;
                          if (!emailRegx.test(value)) {
                            formError.cc = 'Please enter valid email format';
                            this.setState({ formError })
                            return false;
                          } else {
                            formError.cc = '';
                            this.setState({ formError })
                            return true;
                          }

                          // Return boolean to indicate validity

                        }}
                      /> */}

                      <ReactMultiEmail
                        placeholder="Email IDs of External Attendees"
                        //  emails={ccEmails}
                        onChange={(_emails) => {
                          this.setState({ ccEmails: _emails });
                        }}
                        getLabel={(
                          ccEmails,
                          index,
                          removeEmail,
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {ccEmails}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                          </span>
                            </div>
                          );
                        }}
                      />

                      <span className="error"> {this.state.formError.cc ? this.state.formError.cc : ''}<br></br></span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={12} sm={6} className="register-form-left">
                    <div className="custom-ant-select">
                      <div className="form_input_field">
                        <label className="label">
                          From Date & Time{" "}
                          <span className="labelAsterisk"> *</span>
                        </label>

                        <DatePicker
                          className="antd_startDate_picker"
                          format="MM-DD-YYYY hh:mm A"
                          placeholder="MM-DD-YYYY hh:mm A"
                          size="large"
                          value={this.state.dateRangeProps.startDate.inputProps.value ? momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value) : ""}
                          inputReadOnly={true}
                          showNow={false}
                          allowClear={false}
                          onSelect={this.onStartDateChange}
                          getPopupContainer={(node) => node.parentNode}
                          disabledDate={this.valid}
                          showTime={{ defaultValue: moment('00:00', 'HH:mm'), minuteStep: 15 }}
                        />
                        <span className="error">{formError.startDate}</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="register-form-right">
                    <div className="custom-ant-select">
                      <div className="form_input_field">
                        <label className="label">
                          To Date & Time <span className="labelAsterisk"> *</span>
                        </label>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}

                        <DatePicker
                          className="antd_endDate_picker"
                          format="MM-DD-YYYY hh:mm A"
                          placeholder="MM-DD-YYYY hh:mm A"
                          size="large"
                          inputReadOnly={true}
                          showNow={false}
                          allowClear={false}
                          onSelect={this.onEndDateChange}
                          disabled={this.state.dateRangeProps && this.state.dateRangeProps.endDate.inputProps.disabled ? true : false}
                          value={this.state.dateRangeProps.endDate.inputProps.value ? momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value) : ""}
                          getPopupContainer={(node) => node.parentNode}
                          disabledDate={this.disabledDates}
                          showTime={{ defaultValue: moment('00:00', 'HH:mm'), minuteStep: 15 }}
                        />

                        <span className="error">{formError.endDate}</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  {cmsPatientName && cmsPatientName.checked && 
                  <Grid item xs={12} sm={6} className="register-form-left">
                    <div className="custom-ant-select">
                      <div className="form_input_field">
                      <label className="label bl-label">{cmsPatientName && cmsPatientName['Patient Name'] ? cmsPatientName['Patient Name'] : "Patient Name"}</label><CssTextField
                        id="outlined-basic"
                        name="patientName"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        placeholder={`Enter ${cmsPatientName && cmsPatientName['Patient Name'] ? cmsPatientName['Patient Name'] : "Patient Name"}`}
                        onChange={this.handlechecked} />
                      </div>
                    </div>
                  </Grid>}
                  {cmsCaseNumber && cmsCaseNumber.checked && 
                  <Grid item xs={12} sm={6} className="register-form-right">
                    <div className="custom-ant-select">
                      <div className="form_input_field">
                      <label className="label bl-label">{cmsCaseNumber && cmsCaseNumber['Case Number'] ? cmsCaseNumber['Case Number'] : "Case Number"}</label><CssTextField
                        id="outlined-basic"
                        name="caseNumber"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        placeholder={`Enter ${cmsCaseNumber && cmsCaseNumber['Case Number'] ? cmsCaseNumber['Case Number'] : "Case Number"}`}
                        onChange={this.handlechecked} />
                      </div>
                    </div>
                  </Grid>}
                  {cmsPatientSurvey && cmsPatientSurvey.checked && 
                  <Grid item xs={12} sm={6} className="register-form-left">
                    <div className="custom-ant-select">
                      <div className="form_input_field">
                      <label className="label bl-label">{cmsPatientSurvey && cmsPatientSurvey['Patient Survey'] ? cmsPatientSurvey['Patient Survey'] : "Patient Survey"}</label><CssTextField
                        id="outlined-basic"
                        name="patientSurvey"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        placeholder={`Enter ${cmsPatientSurvey && cmsPatientSurvey['Patient Survey'] ? cmsPatientSurvey['Patient Survey'] : "Patient Survey"}`}
                        onChange={this.handlechecked} />
                      </div>
                    </div>
                  </Grid>}
                {<Grid item xs={12} sm={(cmsCaseNumber?.checked && cmsPatientName?.checked) ? 12 : (cmsCaseNumber?.checked  || cmsPatientName?.checked) ? 6  : 12} className="register-form-left"> 
                   <label className="label">Description</label>
                 <CssTextField
                  id="outlined-basic"
                  name="taskDescription"
                  variant="outlined"
                  className="multiline-text"
                  multiline
                  rowsMax={4}
                  type="text"
                  fullWidth
                  placeholder="Enter text here"
                  onChange={this.handlechecked}
                />
                    </Grid>}
                </Grid>
                
                <div className="add_company_action">
                  <Button
                    className="cancel_btn"
                    onClick={this.onClose}
                    variant="outlined"
                  >
                    Cancel
                </Button>
                  <Button
                    className="ok_btn"
                    disabled={this.state.isSubmit}
                    type="submit"
                    variant="contained"
                    onClick={this.addTaskHandler}
                  >
                    {this.state.isSubmit ? <CircularProgress size={20} /> : ''}Add Task
                </Button>
                </div>
              </form>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </div>
    );
  }
}
