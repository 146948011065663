import React, { Component } from "react";
import "./DashboardComponent.css";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import Loader from "react-loader-spinner";
import {
  Box,
  Button,
  Grid,
  Typography

} from "@material-ui/core";
import { Select } from "antd";
import carret_down from "../../Assets/images/carret_down.svg";
import { Tooltip } from 'antd';
import { upperFirst } from "lodash";
import { Calendar } from "antd";
import { connect } from "react-redux";
import { Redirect, withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import { AxiosInstance } from "../../Utilities";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import momentTimeZone from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Scrollbars } from 'react-custom-scrollbars';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog, CircularProgress } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import close from '../../Assets/images/close_btn.svg';
import user_icon from "../../Assets/images/Icon awesome-user-alt.svg";
import email_icon from "../../Assets/images/Icon simple-email.svg";
import phone_icon from "../../Assets/images/Icon awesome-phone-alt.svg";
import building_icon from "../../Assets/images/Icon awesome-building.svg";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities";
import { AddTaskModal } from "../TaskComponent/AddTaskModal";

var hover = 0;
class DashboardComponent extends Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var tempDate = new Date();
    var date =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate();
    var zoneName = momentTimeZone.tz.guess();
    this.state = {
      userRole: userData.role.toLowerCase(),
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      value: momentTimeZone(date).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      selectedValue: momentTimeZone(date).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      events: [],
      upcoming_tasks: [],
      customView: "dayGridMonth",
      taskStatus: "",
      taskName: "",
      assignedTo: "",
      startDate: "",
      endDate: "",
      lat: "",
      long: "",
      loading: true,
      departments: [],
      eventClickId: false,
      showdetails: false,
      response: "",
      detail_id: "",
      departmentId:"",
      isModalOpen: false, 
      selectedDate: null,  
    };
  }

  /**
   * @author Murali
   * To get events and tasks for the current month
   */
  componentDidMount = () => {
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var lastDay = new Date(
    //   date.getFullYear(),
    //   date.getMonth(),
    //   this.daysInMonth(date.getMonth() + 1, date.getFullYear())
    // );

    // var fdate = moment(new Date(firstDay)).utc();
    // var ldate = moment(new Date(lastDay)).utc();
    var firstDay = momentTimeZone().tz(this.state.timeZoneName).startOf("month").utc();
    var lastDay = momentTimeZone().tz(this.state.timeZoneName).endOf("month").utc();
    let data = {
      startDate: firstDay,
      endDate: lastDay,
      timeZone: this.state.timeZoneName
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/dashboard", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status == "200" || response.status === 200)) {
          this.setState({
            events: resp.data.data.tasks,
            upcoming_tasks: resp.data.data.upcoming_tasks,
            loading: false,
          });
        } else {
          console.log("error");
        }
      });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4005
      : "clinical-department", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/get_department")
      .then((resp) => {
        if (resp.status === 200) {
          let response = resp.data.data;
          this.setState({
            departments: response,
          });
        } else {
        }
      });
  };
  /**
   * @author Murali
   * @param {month,year}
   * To get firstdate and last date of a month
   */
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  /**
   * @author Murali
   * @param {events}
   * To display the events/tasks based on status
   */
  formatEvents(events) {
    return events.map((event) => {
      const { title, end, start, taskstatus, type } = event;

      let startTime = new Date(start);
      let endTime = new Date(end);
      return {
        title,
        start: this.getDate(startTime),
        end: this.getDate(endTime),
        className:
          taskstatus === "unassigned"
            ? "unassigned"
            : taskstatus === "pending"
              ? "pending"
              : taskstatus === "assigned"
                ? "assigned"
                : taskstatus === "inprogress"
                  ? "inprogress"
                  : taskstatus === "completed"
                    ? "completed"
                    : taskstatus === "cancelled"
                      ? "cancelled"
                      : "uncovered",
        extendedProps: { ...event },
      };
    });
  }
  /**
   * @author Murali
   * @param {dayString}
   * To format date string to date
   */
  getDate(dayString) {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    return dayString;
  }
  /**
   * @author Murali
   * @param {date/time} value
   * To get the data for particular day/month
   */
  onSelect = (value) => {
    this.setState({ customView: "timeGridDay" }, () => {
      var val = moment(new Date(value._d)).format("YYYY-MM-DD");
      let calendarApi = this.calendarComponentRef.current.getApi();
      calendarApi.gotoDate(val);
    });
  };

  onmouseEnter = (event, jsEvent, view, el) => {
    // this.setState({showdetails:true})
    // console.log("event.view.type000--",event.view.type);
    if (!this.state.eventClickId && (event.view.type === 'dayGridMonth' || event.view.type === "timeGridWeek" || event.view.type === "timeGridDay")) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 4004
        : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
        .get("/dashboard/" + event.event._def.extendedProps._id)
        .then((resp) => {
          let responseData = resp && resp.data;
          if (responseData && (responseData.status === 200 || responseData.status === '200')) {
            let response = responseData.data[0];
            this.setState({
              taskName: response.taskName,
              companyName: response.companyData.name,
              dateTime: 'On ' + moment(response.startDate).format("MMM DD, YYYY h:mm A") + ' to ' + moment(response.endDate).format("MMM DD, YYYY h:mm A"),
              taskStatus: response.taskStatus,
              repName: response.assignedTo.name,
              repPhone: response.assignedTo.phone,
              repEmail: response.assignedTo.email,
              detail_id: response._id,
            }, () => {
              this.setState({
                showdetails: true
              })
            })

          } else if (responseData && (responseData.status === 400 || responseData.status === '400' || responseData.status === 401 || responseData.status === '401')) {

          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (error && (error.status === '500' || error.status === 500)) {
          }

        });
    }
  }
  onmouseLeave = (event, jsEvent, view) => {
    $('#' + event.event._def.extendedProps._id).remove();
  }
  getDeptData = (value) => {
    this.setState({ departmentId: value})
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //   var lastDay = new Date(
    //     date.getFullYear(),
    //     date.getMonth(),
    //     this.daysInMonth(date.getMonth() + 1, date.getFullYear())
    //   );

    //   var fdate = moment(new Date(firstDay)).format("YYYY-MM-DD");
    //   var ldate = moment(new Date(lastDay)).format("YYYY-MM-DD");
    var firstDay = momentTimeZone().tz(this.state.timeZoneName).startOf("month").utc();
    var lastDay = momentTimeZone().tz(this.state.timeZoneName).endOf("month").utc();
    let data = {
      startDate: firstDay,
      endDate: lastDay,
      departmentId: value,
      timeZone: this.state.timeZoneName
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/dashboard", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status == "200" || response.status === 200)) {
          this.setState({
            events: resp.data.data.tasks,
            upcoming_tasks: resp.data.data.upcoming_tasks,
            loading: false,
          });
        } else {
          console.log("error");
        }
      });
  };
  prevButton = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    let calendarType = calendarApi.view.type;
    // var makeDate = new Date(calendarApi.view.currentStart);
    // makeDate.setMonth(makeDate.getMonth() - 1);
    // var firstDay = new Date(makeDate.getFullYear(), makeDate.getMonth(), 1);
    // var lastDay = new Date(makeDate.getFullYear(),makeDate.getMonth(),this.daysInMonth(makeDate.getMonth() + 1, makeDate.getFullYear()));
    // var fdate = moment(new Date(firstDay)).format("YYYY-MM-DD");
    // var ldate = moment(new Date(lastDay)).format("YYYY-MM-DD");

    // var firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).subtract(1, 'month').startOf("month").utc();
    // var lastDay = momentTimeZone(firstDay).tz(this.state.timeZoneName).endOf("month").utc();

    // calendarApi.gotoDate(moment(new Date(firstDay)).format("YYYY-MM-DD"));
    var firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).subtract(1, 'month').startOf("month").utc();
    var lastDay = momentTimeZone(firstDay).tz(this.state.timeZoneName).endOf("month").utc();
   
    var Week_firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).subtract(1, 'week').startOf("week").utc();
    var Week_lastDay = momentTimeZone(Week_firstDay).tz(this.state.timeZoneName).endOf("week").utc();

    var Day_firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).subtract(1,'day').startOf("day").utc();
    var Day_lastDay = momentTimeZone(Day_firstDay).tz(this.state.timeZoneName).endOf("day").utc();

    let data = "";

    if (calendarType === "dayGridMonth") {
      data = {
        startDate: firstDay,
        endDate: lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId

      };
      calendarApi.gotoDate(moment(new Date(firstDay)).format("YYYY-MM-DD"));
    } else if (calendarType === "timeGridWeek") {

      data = {
        startDate: Week_firstDay,
        endDate: Week_lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId
      };
      calendarApi.gotoDate(moment(new Date(Week_firstDay)).format("YYYY-MM-DD"));
    } else if (calendarType === "timeGridDay") {

      data = {
        startDate: Day_firstDay,
        endDate: Day_lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId
      };
      calendarApi.gotoDate(moment(new Date(Day_firstDay)).format("YYYY-MM-DD"));
    } 

   
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/dashboard", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status == "200" || response.status === 200)) {
          this.setState({
            events: resp.data.data.tasks,
          });
        } else {
          console.log("error");
        }
      });
  }
  nextButton = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    let calendarType = calendarApi.view.type;
    // var makeDate = new Date(calendarApi.view.currentStart);
    // makeDate.setMonth(makeDate.getMonth() + 1);
    // var firstDay = new Date(makeDate.getFullYear(), makeDate.getMonth(), 1);
    // var lastDay = new Date(makeDate.getFullYear(),makeDate.getMonth(),this.daysInMonth(makeDate.getMonth() + 1, makeDate.getFullYear()));
    // var fdate = moment(new Date(firstDay)).format("YYYY-MM-DD");
    // var ldate = moment(new Date(lastDay)).format("YYYY-MM-DD");

    var firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).add(1,'month').startOf("month").utc();
    var lastDay = momentTimeZone(firstDay).tz(this.state.timeZoneName).endOf("month").utc();
   
    var Week_firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).add(1, 'week').startOf("week").utc();
    var Week_lastDay = momentTimeZone(Week_firstDay).tz(this.state.timeZoneName).endOf("week").utc();

    var Day_firstDay = momentTimeZone(calendarApi.view.currentStart).tz(this.state.timeZoneName).add(1, 'day').startOf("day").utc();
    var Day_lastDay = momentTimeZone(Day_firstDay).tz(this.state.timeZoneName).endOf("day").utc();

    let data = "";

    if (calendarType === "dayGridMonth") {
      data = {
        startDate: firstDay,
        endDate: lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId

      };
      calendarApi.gotoDate(moment(new Date(firstDay)).format("YYYY-MM-DD"));
    } else if (calendarType === "timeGridWeek") {

      data = {
        startDate: Week_firstDay,
        endDate: Week_lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId
      };
      calendarApi.gotoDate(moment(new Date(Week_firstDay)).format("YYYY-MM-DD"));
    } else if (calendarType === "timeGridDay") {

      data = {
        startDate: Day_firstDay,
        endDate: Day_lastDay,
        timeZone: this.state.timeZoneName,
        departmentId: this.state.departmentId
      };
      calendarApi.gotoDate(moment(new Date(Day_firstDay)).format("YYYY-MM-DD"));
    } 

    // calendarApi.gotoDate(moment(new Date(firstDay)).format("YYYY-MM-DD"));
    // calendarApi.gotoDate(fdate);
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/dashboard", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status == "200" || response.status === 200)) {
          this.setState({
            events: resp.data.data.tasks,
          });
        } else {
          console.log("error");
        }
      });
  }

  handleDateClick = (arg) => {
    // Open the modal and pass the clicked date
    this.setState({ isModalOpen: true, selectedDate: arg.dateStr });
  };

  // Close modal
  closeModal = () => {
    this.setState({ isModalOpen: false, selectedDate: null });
  };

  updateAddedTask = () => {
    this.componentDidMount();
  }

  render() {
    const {
      value,
      events,
      customView,
      taskName,
      taskStatus,
      startDate,
      endDate,
      assignedTo,
      upcoming_tasks,
      lat,
      long,
      loading,
      userRole
    } = this.state;
    let handleClose = () => this.setState({ showdetails: false });
    const { Option } = Select;
    let CompanyPage = (
      <div className="body-bg-color">
        <HeaderComponent />
        {this.state.isModalOpen && (
          <AddTaskModal
            open={this.state.isModalOpen}
            selectedDate={this.state.selectedDate}
            onClose={this.closeModal}
            taskAdded={this.updateAddedTask}
          />
        )}
        <Dialog open={this.state.showdetails} TransitionComponent={TransitionGrow} transitionDuration={600} className="events_detail_modal">
          {/* <DialogTitle className="events_detail_title">
            {" "}
            <img
              src={close}
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </DialogTitle> */}
          <DialogContent className="events_detail_content">
            <DialogContentText id="alert-dialog-description">
              <div className="events_detail_header">
                <div className="events_detail_name">
                  <div>
                    <NavLink to={'/task/' + this.state.detail_id} className="navbars">
                      <h4>{this.state.taskName
                      ? this.state.taskName.charAt(0).toUpperCase() +
                      this.state.taskName.slice(1)
                      : "-"}</h4>
                    </NavLink>

                    <span className={this.state.taskStatus ? "events_type_" + this.state.taskStatus.toLowerCase() : ""}>{this.state.taskStatus
                      ? this.state.taskStatus.charAt(0).toUpperCase() +
                      this.state.taskStatus.slice(1)
                      : "-"}</span>
                  </div>
                  <img
                    src={close}
                    alt="close"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                <h5 className="event_time">{this.state.dateTime}</h5>
                <div className="events_detail_section"><img src={building_icon} /><span>{this.state.companyName}</span></div>
              </div>
              {this.state.taskStatus === "Unassigned" || this.state.taskStatus === "cancelled" || this.state.taskStatus === "Pending" ?

                "" :
                <>
                  <h6 className="sales_rep_heading">Sales Rep Details</h6>
                  <div className="sales_rep_details">
                    <div className="events_detail_section"><img src={user_icon} /><span>{this.state.repName ? this.state.repName : "--"}</span></div>
                    <div className="events_detail_section"><img src={phone_icon} /><span>   <a href={"tel:" + this.state.repPhone}>{this.state.repPhone ? this.state.repPhone : "--"}</a></span></div>
                    <div className="events_detail_section sales_rep_email"><img src={email_icon} /><span> <a href={"mailto:" + this.state.repEmail}>{this.state.repEmail ? this.state.repEmail : "--"}</a></span></div>

                  </div>
                </>
              }
              { }
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Box component="span" className="company-comp">
          <div className="calender-page">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <div className="calender-left-grid">
                  <h2 className="cal-task-selected-title">
                    <span>Pending</span>
                    {userRole !== 'scheduler' ?
                      <Select
                        className="dashboard_ant_select selectBoi"
                        placeholder="Department"
                        name="departmentId"
                        suffixIcon={
                          <img className="carret" src={carret_down} alt="carret" />
                        }
                        onChange={this.getDeptData}
                      >   <Option value=''>All</Option>
                        {this.state.departments.map((department) => (
                          <Option value={department._id}>
                            {department.name}
                          </Option>
                        ))}
                      </Select> : ''}
                  </h2>
                  <div className="calender-left-container">
                    <Scrollbars className="custom_scrollbar" style={{ width: "auto", height: 519 }}>
                      <div className="cal-task-selected">
                        {loading ?
                          <div style={{ textAlign: 'center' }}>
                            <Loader
                              type="ThreeDots"
                              color="#00BFFF"
                              height={50}
                              width={50}
                              visible={loading}
                            /></div> :
                          upcoming_tasks.length !== 0 ? (
                            upcoming_tasks.map((tasks, index) => (
                              <>
                                <span
                                  className={
                                    tasks.taskstatus + " cal-task-selected-status"
                                  }
                                >
                                  {tasks.taskstatus !== undefined
                                    ? tasks.taskstatus.charAt(0).toUpperCase() +
                                    tasks.taskstatus.slice(1)
                                    : ""}
                                </span>
                                <Tooltip title="View Details" placement="bottomLeft">
                                  <a>
                                    <p className="cal-task-selected-text" onClick={() => window.location.href = '/task/' + tasks._id}>
                                      <span>{upperFirst(tasks.title.charAt(0).toUpperCase() +
                                        tasks.title.slice(1))}</span>
                                    </p>
                                  </a>
                                </Tooltip>
                                <>
                                  {tasks.assignedTo !== "" &&
                                    tasks.assignedTo !== undefined ? (
                                      <div className="cal-task-selected-assigne">
                                        Assigned To:<span>{tasks.assignedTo}</span>
                                      </div>
                                    ) : (
                                      "Unassigned"
                                    )}
                                </>
                                <div className="cal-task-selected-date">
                                  On{" "}
                                  {moment(tasks.start).format(
                                    "MMM DD, YYYY h:mm A"
                                  )}{" "}
                              to{" "}
                                  {moment(tasks.end).format("MMM DD, YYYY h:mm A")}
                                </div>
                              </>
                            ))
                          ) : (
                              <div>No Upcoming Tasks</div>
                            )}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8} lg={9} className="events_calender">
                <FullCalendar
                  customButtons={{
                    prevButton: {
                      text: "<",
                      id: 'prev',
                      click: this.prevButton,
                    },
                    nextButton: {
                      text: ">",
                      click: this.nextButton,
                    },
                  }}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prevButton,nextButton",
                    center: "title",
                    right: "timeGridDay,timeGridWeek,dayGridMonth",
                  }}
                  // dateClick={this.onmouseEnter}
                  views={customView}
                  dayMaxEventRows={5}
                  events={this.formatEvents(events)}
                  eventClick={this.onmouseEnter}
                  eventMouseLeave={this.onmouseLeave}
                  displayEventTime={false}
                  ref={this.calendarComponentRef}
                  dateClick={(e)=>this.handleDateClick(e)}
                  slotLabelFormat={{
                    hour: 'numeric',
                    omitZeroMinute: false,
                    hour12: true
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardComponent));
